const SocialMenu = () => {
  return (
    <div className="flex gap-2 justify-center md:justify-end lg:pt-2 w-full">
      
      <a href="//www.behance.net/joselamela" target="_blank" rel="noreferrer">
        <svg id="Group_10" data-name="Group 10" xmlns="http://www.w3.org/2000/svg" width="29.218" height="29.218" viewBox="0 0 29.218 29.218">
          <path id="Path_3" data-name="Path 3" d="M-262.352,265.806a1.68,1.68,0,0,0-.639-.314,3.818,3.818,0,0,0-1-.11h-2.235v3.348h2.254a2.9,2.9,0,0,0,1.037-.157,1.637,1.637,0,0,0,.63-.406A1.358,1.358,0,0,0-262,267.6a2.427,2.427,0,0,0,.085-.636,2.074,2.074,0,0,0-.1-.664A1.1,1.1,0,0,0-262.352,265.806Z" transform="translate(274.004 -249.795)" fill="#0e1d50" />
          <path id="Path_4" data-name="Path 4" d="M-263.607,254.773a2.011,2.011,0,0,0,.725-.258,1.2,1.2,0,0,0,.454-.507,1.861,1.861,0,0,0,.156-.812,2.231,2.231,0,0,0-.123-.8,1.145,1.145,0,0,0-.379-.512,1.605,1.605,0,0,0-.649-.277,4.593,4.593,0,0,0-.933-.083h-1.875v3.32h1.667A5.63,5.63,0,0,0-263.607,254.773Z" transform="translate(274.004 -241.343)" fill="#0e1d50" />
          <path id="Path_5" data-name="Path 5" d="M-265.427,225.417h-12.251a8.483,8.483,0,0,0-8.483,8.483v12.251a8.483,8.483,0,0,0,8.483,8.483h12.251a8.483,8.483,0,0,0,8.483-8.483V233.9A8.483,8.483,0,0,0-265.427,225.417Zm-3.423,9.4h6.034V236.2h-6.034Zm-2.4,9.435a3.656,3.656,0,0,1-1,1.286,4.785,4.785,0,0,1-1.625.858,7.237,7.237,0,0,1-2.206.309h-5.512V233.267h5.076a9.93,9.93,0,0,1,2.434.258,4.7,4.7,0,0,1,1.638.729,2.78,2.78,0,0,1,.924,1.143,3.783,3.783,0,0,1,.288,1.5,2.873,2.873,0,0,1-.132.871,2.484,2.484,0,0,1-.417.784,3.22,3.22,0,0,1-.729.668,4.611,4.611,0,0,1-1.07.525,3.851,3.851,0,0,1,2.017,1.042,2.665,2.665,0,0,1,.653,1.863A3.751,3.751,0,0,1-271.248,244.251Zm9.665-1.654-5.854-.016a2.023,2.023,0,0,0,1.952,2.2c1.213,0,1.855-.589,1.83-1.107h1.923a3.5,3.5,0,0,1-3.009,3.11,5.081,5.081,0,0,1-2.593-.25,4.17,4.17,0,0,1-1.488-.942,4.43,4.43,0,0,1-1.007-1.553,5.818,5.818,0,0,1-.37-2.16,4.889,4.889,0,0,1,.317-1.758,4.317,4.317,0,0,1,.909-1.455,4.338,4.338,0,0,1,1.435-.991,4.738,4.738,0,0,1,1.9-.365,4.742,4.742,0,0,1,1.679.285,3.661,3.661,0,0,1,1.31.83,3.434,3.434,0,0,1,.917,1.534A6.771,6.771,0,0,1-261.583,242.6Z" transform="translate(286.162 -225.417)" fill="#0e1d50" />
          <path id="Path_6" data-name="Path 6" d="M-234.982,261.369a1.407,1.407,0,0,0-.5-.4,1.724,1.724,0,0,0-.757-.152,1.654,1.654,0,0,0-1.23.45,2.352,2.352,0,0,0-.588,1.3h3.439a2.446,2.446,0,0,0-.085-.643A1.565,1.565,0,0,0-234.982,261.369Z" transform="translate(256.821 -247.013)" fill="#0e1d50" />
        </svg>
      </a>

      <a href="//www.linkedin.com/in/jose-manuel-lamela-rey-725713a7/" target="_blank" rel="noreferrer">
        <svg xmlns="http://www.w3.org/2000/svg" width="29.218" height="29.218" viewBox="0 0 29.218 29.218">
          <path id="Path_17" data-name="Path 17" d="M-265.427,102.872h-12.251a8.484,8.484,0,0,0-8.483,8.484v12.251a8.483,8.483,0,0,0,8.483,8.483h12.251a8.483,8.483,0,0,0,8.483-8.483V111.356A8.484,8.484,0,0,0-265.427,102.872Zm-10.811,22.34h-3.456v-10.9h3.456Zm-1.728-11.749a1.856,1.856,0,0,1-1.856-1.856,1.856,1.856,0,0,1,1.856-1.856,1.856,1.856,0,0,1,1.856,1.856A1.856,1.856,0,0,1-277.966,113.462Zm14.929,11.749h-3.457v-6.162a1.955,1.955,0,0,0-1.8-2.125,2.221,2.221,0,0,0-2.05,2.125v6.162H-273.8v-11.22h3.687l-.231,1.466h.039a3.646,3.646,0,0,1,2.871-1.466c1.836,0,4.394,1,4.394,4.038Z" transform="translate(286.162 -102.872)" fill="#0e1d50" />
        </svg>
      </a>

      <a href="https://www.instagram.com/azuldelachus/?hl=es" target="_blank" rel="noreferrer">
        <svg id="Group_12" data-name="Group 12" xmlns="http://www.w3.org/2000/svg" width="29.218" height="29.218" viewBox="0 0 29.218 29.218">
          <path id="Path_10" data-name="Path 10" d="M-156.573,357.235h-12.251a8.484,8.484,0,0,0-8.483,8.483V377.97a8.483,8.483,0,0,0,8.483,8.483h12.251a8.483,8.483,0,0,0,8.483-8.483V365.718A8.484,8.484,0,0,0-156.573,357.235Zm2.889,20.446a3.181,3.181,0,0,1-3.178,3.178h-11.674a3.181,3.181,0,0,1-3.178-3.178V366.007a3.181,3.181,0,0,1,3.178-3.178h11.674a3.181,3.181,0,0,1,3.178,3.178Z" transform="translate(177.308 -357.235)" fill="#0e1d50" />
          <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="2.84" cy="2.84" rx="2.84" ry="2.84" transform="matrix(0.993, -0.122, 0.122, 0.993, 11.444, 12.138)" fill="#0e1d50" />
          <path id="Rectangle_5" data-name="Rectangle 5" d="M1.495,0h0A1.495,1.495,0,0,1,2.99,1.495v0A1.495,1.495,0,0,1,1.5,2.99h0A1.495,1.495,0,0,1,0,1.495v0A1.495,1.495,0,0,1,1.495,0Z" transform="translate(17.497 8.314)" fill="#0e1d50" />
          <path id="Path_11" data-name="Path 11" d="M-150.567,398.974a4.287,4.287,0,0,1-4.282-4.282h-2.569v5.837a1.015,1.015,0,0,0,1.014,1.014h11.674a1.015,1.015,0,0,0,1.014-1.014v-5.837h-2.569A4.287,4.287,0,0,1-150.567,398.974Z" transform="translate(165.176 -380.083)" fill="#0e1d50" />
        </svg>

      </a>
    </div>
  );
};

export default SocialMenu;
