import useMedia from 'use-media';

import Tauteo from './branding/Tauteo';
import Octopus from './branding/Octopus';
import ReturnToTopArrow from './ReturnToTopArrow';

const Branding = () => {
  const isMobile = useMedia({ maxWidth: '640px' });
  return (
    <div className="flex flex-col md:flex-col p-8 gap-4 md:px-24 md:pt-4 md:pb-8 md:gap-16">
      <Tauteo isMobile={isMobile} />
      <Octopus isMobile={isMobile} />
      <ReturnToTopArrow />
    </div>
  );
};

export default Branding;
