import { useEffect, FC } from 'react';
import ReactGA from 'react-ga';

import { Route, Switch, useLocation } from 'react-router-dom';

import LandingView from './components/LandingView';
import Menu from './components/Menu';
import MenuMobile from './components/MenuMobile';

import AboutMe from './components/AboutMe';
import Projects from './components/Projects';
import Branding from './components/Branding';
import Photography from './components/Photography';
import Editorial from './components/Editorial';
import GrupoSIM from './components/GrupoSIM';

import './App.css';
import useMedia from 'use-media';

function usePageViews() {
	let location = useLocation();
	useEffect(() => {
		ReactGA.pageview(location.pathname);
	}, [location]);
}

const NarrowContainer: FC<{}> = ({ children }) => (
	<div className="md:max-w-screen-xl md:m-auto">{children}</div>
);

function App() {
	useEffect(() => {
		const trackingId = process.env.REACT_APP_TRACKING_ID;
		if (trackingId) ReactGA.initialize(trackingId);
	}, []);

	usePageViews();

	const isMobile = useMedia({maxWidth: '768px'});
	// const columnCount = useMedia<number>(
	// 	// Media queries
	// 	['(min-width: 768px)', '(min-width: 640px)'],
	// 	// Column counts (relates to above media queries by array index)
	// 	[2, 1],
	// 	// Default column count
	// 	1
	// );

	// const isMobile = columnCount === 1;

	return (
		<div className="flex flex-col h-full">
			{isMobile ? <MenuMobile /> : <Menu />}
			<div id="main" className="h-full overflow-y-auto">
				<Switch>
					<NarrowContainer>
						<Route path="/acerca-de-mi">
							<AboutMe />
						</Route>
						<Route path="/proyectos">
							<Projects />
						</Route>
						<Route path="/branding">
							<Branding />
						</Route>
						<Route path="/editorial">
							<Editorial />
						</Route>
						<Route path="/fotografia">
							<Photography />
						</Route>
						<Route path="/grupo-sim">
							<GrupoSIM  />
						</Route>
					</NarrowContainer>
				</Switch>
				<Switch>
					<Route exact path="/">
						<LandingView />
					</Route>
				</Switch>
			</div>
		</div>
	);
}

export default App;
