import Content from '../../content/mapa-cultural-cadiz';

interface CulturaMapaCadizProps {
	isMobile: boolean;
}

const CulturaMapaCadiz = ({ isMobile }: CulturaMapaCadizProps) => {
	return (
		<>
			<div className="flex flex-col md:flex-row p-8 md:px-24 md:pt-4 md:pb-8 md:gap-16">
				<div className="">
					<h2 className="my-8">{Content.Title} </h2>
					<section
						className="content text-justify"
						dangerouslySetInnerHTML={{
							__html: Content.Content,
						}}
					></section>
				</div>

				{!isMobile && (
					<img
						className="w-full md:w-1/3 object-contain self-end shadow-lg rounded-lg"
						style={{ maxWidth: '394px', maxHeight: '550px' }}
						alt="Filosofía del paseante"
						src={Content.Image}
						srcSet={`
                  ${Content.Image} 300w, 
                  ${Content.Image_2x} 900w`}
						sizes={`(min-width: 932px) 932px, 100vw`}
					></img>
				)}

				{isMobile && (
					<div className="flex flex-col gap-3">
						<div className="flex flex-row gap-2">
							<div className="flex flex-col w-1/2 gap-8">
								<img
									className="object-contain shadow-lg"
									alt="Filosofía del paseante"
									src={Content.Image}
								></img>
								<div className="flex flex-row">
									{Content.Iconos.map((item, key) => {
										return (
											<img
												key={key}
												className="object-contain w-8 "
												alt={`Icono-${key}`}
												src={item}
											></img>
										);
									})}
								</div>
							</div>
							<img
								className="w-1/2 object-contain shadow-lg"
								alt="Filosofía del paseante"
								src={Content.Mapa}
								srcSet={`${Content.Mapa} 300w, ${Content.Mapa_2x} 900w`}
								sizes={`(min-width: 932px) 932px, 100vw`}
							></img>
						</div>
						<img
							className="shadow-lg rounded-lg"
							alt="Filosofía del paseante"
							src={Content.Booklet}
						/>
						<img
							className="shadow-lg rounded-lg"
							alt="Filosofía del paseante"
							src={Content.BookletExterior}
						/>
						<img
							className="shadow-lg rounded-lg"
							alt="Filosofía del paseante"
							src={Content.Booklet}
						/>
						<div className="flex flex-row gap-2">
							<img
								className="shadow-lg rounded-lg w-1/2"
								alt="Filosofía del paseante"
								src={Content.Sample1}
							/>
							<img
								className="shadow-lg rounded-lg w-1/2"
								alt="Filosofía del paseante"
								src={Content.Sample2}
							/>
						</div>
					</div>
				)}
			</div>

			{!isMobile && (
				<div className="flex flex-col p-8 md:px-24 md:pt-4 md:pb-8 md:gap-16">
					<div className="flex flex-row">
						<img
							className="shadow-lg rounded-lg w-1/2 mr-2"
							alt="Cultura Mapa Cádiz Guía"
							src={Content.Booklet_2x}
						/>
						<img
							className="shadow-lg rounded-lg w-1/2 ml-2"
							alt="Cultura Mapa Cádiz Exterior"
							src={Content.BookletExterior_2x}
						/>
					</div>
					<div className="flex flex-row">
						<img
							className="shadow-lg rounded-lg w-1/2 mr-2"
							alt="Cultura Mapa Cádiz Guía"
							src={Content.Image_2x}
						/>
						<img
							className="shadow-lg rounded-lg w-1/2 ml-2"
							alt="Cultura Mapa Cádiz Exterior"
							src={Content.Mapa_2x}
						/>
					</div>

					<div className="flex flex-row">
						<img
							className="shadow-lg rounded-lg w-1/2 mr-2"
							alt="Cultura Mapa Cádiz Guía"
							src={Content.Sample1_2x}
						/>
						<img
							className="shadow-lg rounded-lg w-1/2 ml-2"
							alt="Cultura Mapa Cádiz Exterior"
							src={Content.Sample2_2x}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default CulturaMapaCadiz;
