import { TwoImageContent } from './Content';

import cartelFdN from '../images/cartel-filosofia-del-paseante.png';
import cartelFdN2x from '../images/cartel-filosofia-del-paseante@2x.png';

import interiorFdN from '../images/filosofia-del-paseante-interior.png';
import interiorFdN2x from '../images/filosofia-del-paseante-interior@2x.png';

import cartelSA from '../images/cartel-ser-aqui.png';
import cartelSA2x from '../images/cartel-ser-aqui@2x.png';

import interiorSA from '../images/catalogo-ser-aqui.png';
import interiorSA2x from '../images/catalogo-ser-aqui@2x.png';



export const FilosofiaDelPaseanteProyecto: TwoImageContent = {
	Title: 'La filosofía del paseante',
	Content: `<p class="lg:text-justify">La Filosofía del Paseante, se trata de una exposición de arte que realizó el artista roteño Román Lokati en el Museo de Cádiz.
    <br/><br/>Se realizó todo lo relacionado con respecto al diseño de la exposición, desde la cartelería, edición fotográfica, el catálogo de la misma, así como los vinilos de la explicación de la exposición.</p>`,
	Image: cartelFdN,
	Image_2x: cartelFdN2x,
	AltImage: 'careta',
	Image2: interiorFdN,
	AltImage2: 'careta',
	Image2_2x: interiorFdN2x,
};

export const SerAquiProyecto: TwoImageContent = {
	Title: 'Ser-Aquí',
	Content: `<p>Ser-Aquí fue  una exposición de artistas gaditanos, nacidos en la década de los 80 para la Diputación de Cádiz, que se expuso en el Palacio Provincial de Diputación.</p>
    <p>Este fue un proyecto bastante completo, ya que me encargué de todo el diseño, además de la publicidad (vídeos, banner, etc...) así como de las Redes Sociales.</p>
    <p>En cuanto al diseño, se realizó la cartelería, el catálogo, todo tipo de publicaciones de redes sociales (tanto de instagram como facebook), las cuartelas, lona publicitaria, etc...</p>`,
	Image: cartelSA,
	Image_2x: cartelSA2x,
	AltImage: 'careta',
	Image2: interiorSA,
	Image2_2x: interiorSA2x,
	AltImage2: 'careta',
};

const content = [
	FilosofiaDelPaseanteProyecto,
	SerAquiProyecto
];

export default content;
