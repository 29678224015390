import SocialMenu from './SocialMenu';

const LandingView = () => {
	return (
		<div className="flex flex-col p-4 text-center md:p-0 md:text-left md:flex-row items-center justify-center h-full">
			<div className="introduction center md:w-1/2 md:p-16 lg:ml-20 lg:w-1/3 lg:p-16">
				<h1 className="">¡Hola!</h1>
				<p>
					Me llaman Josete, Joselito, Joselete, Pepe... contesto con cualquier
					nombre que derive de Jose, aunque para asuntos serios me presento como
					Jose&nbsp;Manuel.
				</p>
				<div className="my-4">
					<SocialMenu />
				</div>
			</div>
			<div className="portada bg-contain bg-center w-full md:bg-bottom h-full"></div>
		</div>
	);
};

export default LandingView;
