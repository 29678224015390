
import { grupoSIMContent } from '../../content/grupo-sim';

interface GenericProps {
  isMobile: boolean;
}

const OpelGuias = ({ isMobile }: GenericProps) => {
  return (
    <>
      <h3 className="my-8">{grupoSIMContent.subSectionOpelGuias}</h3>
      <div className="flex flex-row gap-2 md:gap-8 pt-2 justify-evenly flex-wrap">
        <img
          className="object-contain shadow-lg rounded-lg"
          alt={grupoSIMContent.logoOpelHint}
          src={grupoSIMContent.opelGuias1}
          srcSet={`
                  ${grupoSIMContent.opelGuias1} 300w, 
                  ${grupoSIMContent.opelGuias1_2x} 900w`}
          sizes={`(min-width: 932px) 932px, 100vw`}
        />
        <div className="shadow-lg rounded-lg">
          <img
            className="object-contain"
            alt={grupoSIMContent.logoOpelHint}
            src={grupoSIMContent.opelGuias2}
            srcSet={`
                  ${grupoSIMContent.opelGuias2} 300w, 
                  ${grupoSIMContent.opelGuias2_2x} 900w`}
            sizes={`(min-width: 932px) 932px, 100vw`}
          />
        </div>
        <img
          className="object-contain shadow-lg rounded-lg"
          alt={grupoSIMContent.logoOpelHint}
          src={grupoSIMContent.opelGuias3}
          srcSet={`
                  ${grupoSIMContent.opelGuias3} 300w, 
                  ${grupoSIMContent.opelGuias3_2x} 900w`}
          sizes={`(min-width: 932px) 932px, 100vw`}
        />
      </div>
    </>
  );
};

export default OpelGuias;
