import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Logo from './Logo';
import MenuRoutes from '../content/MenuRoutes';
import BurgerButton from './BurgerButton';
import CloseButton from './CloseButton';

const Menu = () => {
	const [displayMenu, setDisplayMenu] = useState(false);
	const location = useLocation();

	const route = MenuRoutes.filter((route) => route.path === location.pathname);
	const textRoute = route[0]?.text;

	var buttonColor = 'white';
	if (textRoute === undefined) {
		buttonColor = '#0E1D50';
	}

	return (
		<div className="flex justify-between w-full">
			<Logo />
			<div className="menu-mobile block flex flex-col items-end">
				<div>
					{textRoute === undefined ? (
						<div
							className="w-max py-2 px-4 mt-8 mr-8 mb-2 flex flex-row justify-end items-center "
							onClick={() => setDisplayMenu(!displayMenu)}
						>
							{displayMenu ? (
								<CloseButton color={buttonColor} />
							) : (
								<BurgerButton color={buttonColor} />
							)}
						</div>
					) : (
						<div
							className="py-2 px-4 mt-8 mr-8 mb-2 flex flex-row justify-end items-center bg-blue-primary rounded-full"
							onClick={() => setDisplayMenu(!displayMenu)}
						>
							<span className="mr-4 text-white">{textRoute}</span>
							{displayMenu ? (
								<CloseButton color={buttonColor} />
							) : (
								<BurgerButton color={buttonColor} />
							)}
						</div>
					)}
				</div>
				<div>
					{displayMenu && (
						<ul className="flex flex-col text-blue justify-end items-end">
							{MenuRoutes.map((route, index) => {
								const active = location.pathname === route.path ? 'active' : '';

								return (
									<Link
										key={index}
										to={route.path}
										onClick={() => setDisplayMenu(!displayMenu)}
									>
										<li className={active}>{route.text}</li>
									</Link>
								);
							})}
						</ul>
					)}
				</div>
			</div>
		</div>
	);
};

export default Menu;
