// import Content from '../content/fotografia';
import useMedia from 'use-media';
import Fotomontajes from './fotografia/Fotomontajes';
import Collages from './fotografia/Collages';
import ReturnToTopArrow from './ReturnToTopArrow';

const Photography = () => {
	const isMobile = useMedia({ maxWidth: '640px' });
  return (
    <>
      <Fotomontajes isMobile={isMobile} />
      <Collages isMobile={isMobile} />
      <ReturnToTopArrow/>
    </>
  );
};

export default Photography;

