
import { tauteoContent } from '../../content/branding';

interface TauteoProps {
  isMobile: boolean;
}


const Tauteo = ({ isMobile }: TauteoProps) => {

  return (
    <>
      <div className="flex flex-col md:flex-col gap-4 md:gap-16">
        <div className="flex flex-col md:flex-row md:gap-4 md:justify-between">
          <div className="md:w-2/3">
            <h2 className="my-8">{tauteoContent.Title} </h2>
            <section
              className="content text-justify"
              dangerouslySetInnerHTML={{
                __html: tauteoContent.Content,
              }}
            ></section>
          </div>
          {!isMobile && (
            <img
              className="w-full md:w-auto object-contain self-end md:max-h-80 md:max-w-2xl"
              alt={tauteoContent.AltImage}
              src={tauteoContent.Image}
              srcSet={`
                  ${tauteoContent.Image} 300w, 
                  ${tauteoContent.Image_2x} 900w`}
              sizes={`(min-width: 932px) 932px, 100vw`}
            ></img>
          )}
        </div>
        {isMobile && (
          <div className="flex flex-row gap-4">
            <img
              className="w-1/3 object-contain self-end"

              alt={tauteoContent.AltImage}
              src={tauteoContent.Image}
              srcSet={`
                  ${tauteoContent.Image} 300w, 
                  ${tauteoContent.Image_2x} 900w`}
              sizes={`(min-width: 932px) 932px, 100vw`}
            ></img>
            <img
              className="w-2/3 object-contain self-center shadow-lg rounded-lg"
              alt={tauteoContent.TauteoBook}
              src={tauteoContent.TauteoBook}
              srcSet={`
                ${tauteoContent.TauteoBook} 300w, 
                ${tauteoContent.TauteoBook_2x} 900w`}
              sizes={`(min-width: 932px) 932px, 100vw`}
            ></img>
          </div>
        )}
        <div className="flex flex-row gap-4 md:gap-4 md:justify-between">
          {!isMobile && (
            <img
              className="w-1/2 object-contain self-center md:max-h-80 md:max-w-2xl shadow-lg rounded-lg"
              alt={tauteoContent.TauteoBook}
              src={tauteoContent.TauteoBook}
              srcSet={`
                  ${tauteoContent.TauteoBook} 300w, 
                  ${tauteoContent.TauteoBook_2x} 900w`}
              sizes={`(min-width: 932px) 932px, 100vw`}
            ></img>
          )}
          <div className="flex flex-row w-full md:w-auto md:flex-row gap-4 md:gap-4 md:justify-between">
            <img className="w-full md:w-auto object-contain self-end md:max-h-80 md:max-w-2xl"
              alt={tauteoContent.Sample1Alt}
              src={tauteoContent.Sample1}
              srcSet={`
                  ${tauteoContent.Sample1} 300w, 
                  ${tauteoContent.Sample1_2x} 900w`}
              sizes={`(min-width: 932px) 932px, 100vw`}
            ></img>
            <img className="w-full md:w-auto object-contain self-end md:max-h-80 md:max-w-2xl"
              alt={tauteoContent.Sample2Alt}
              src={tauteoContent.Sample2}
              srcSet={`
                  ${tauteoContent.Sample2} 300w, 
                  ${tauteoContent.Sample2_2x} 900w`}
              sizes={`(min-width: 932px) 932px, 100vw`}
            ></img>
            <img className="w-full md:w-auto object-contain self-end md:max-h-80 md:max-w-2xl"
              alt={tauteoContent.Sample3Alt}
              src={tauteoContent.Sample3}
              srcSet={`
                  ${tauteoContent.Sample3} 300w, 
                  ${tauteoContent.Sample3_2x} 900w`}
              sizes={`(min-width: 932px) 932px, 100vw`}
            ></img>
            <img className="w-full md:w-auto object-contain self-end md:max-h-80 md:max-w-2xl"
              alt={tauteoContent.Sample4Alt}
              src={tauteoContent.Sample4}
              srcSet={`
                  ${tauteoContent.Sample4} 300w, 
                  ${tauteoContent.Sample4_2x} 900w`}
              sizes={`(min-width: 932px) 932px, 100vw`}
            ></img>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-4 md:gap-4 md:justify-between">
          <div className="flex flex-row gap-8 md:gap-6 md:mr-8 md:justify-between md:w-1/2">
            <img className="w-full md:w-auto object-contain self-end md:max-h-80 md:max-w-2xl shadow-lg rounded-lg"
              alt={tauteoContent.Tradicion1Alt}
              src={tauteoContent.Tradicion1}
              srcSet={`
                  ${tauteoContent.Tradicion1} 300w, 
                  ${tauteoContent.Tradicion1_2x} 900w`}
              sizes={`(min-width: 932px) 932px, 100vw`}
            ></img>

            <img className="w-full md:w-auto object-contain self-end md:max-h-80 md:max-w-2xl shadow-lg rounded-lg"
              alt={tauteoContent.Tradicion2Alt}
              src={tauteoContent.Tradicion2}
              srcSet={`
                  ${tauteoContent.Tradicion2} 300w, 
                  ${tauteoContent.Tradicion2_2x} 900w`}
              sizes={`(min-width: 932px) 932px, 100vw`}
            ></img>
          </div>
          <div className="flex flex-row gap-8 md:gap-6 md:ml-8 md:justify-between md:w-1/2">
            <img className="w-full md:w-auto object-contain self-end md:max-h-80 md:max-w-2xl shadow-lg rounded-lg"
              alt={tauteoContent.Tradicion3Alt}
              src={tauteoContent.Tradicion3}
              srcSet={`
                    ${tauteoContent.Tradicion3} 300w, 
                    ${tauteoContent.Tradicion3_2x} 900w`}
              sizes={`(min-width: 932px) 932px, 100vw`}
            ></img>

            <img className="w-full md:w-auto object-contain self-end md:max-h-80 md:max-w-2xl shadow-lg rounded-lg"
              alt={tauteoContent.Tradicion4Alt}
              src={tauteoContent.Tradicion4}
              srcSet={`
                    ${tauteoContent.Tradicion4} 300w, 
                    ${tauteoContent.Tradicion4_2x} 900w`}
              sizes={`(min-width: 932px) 932px, 100vw`}
            ></img>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tauteo;
