
import { collagesContent } from '../../content/fotografia';

interface CollagesProps {
  isMobile: boolean;
}

const Collages = ({ isMobile }: CollagesProps) => {
  return (
    <>
      <div className="flex flex-col md:flex-col p-8 md:px-24 md:pt-4 md:pb-8 md:gap-16">
        <div className="flex flex-col md:flex-row md:gap-8 md:justify-between">
          <div className="md:w-7/12 md:pr-8">
            <h2 className="my-8">{collagesContent.Title} </h2>
            <section
              className="content text-justify"
              dangerouslySetInnerHTML={{
                __html: collagesContent.Content,
              }}
            ></section>
          </div>
          {!isMobile && (
            <img
              className="w-full md:w-auto object-contain self-end md:max-h-80 md:max-w-2xl shadow-md rounded-md"
              alt={collagesContent.AltImage}
              src={collagesContent.Image}
              srcSet={`
                  ${collagesContent.Image} 300w, 
                  ${collagesContent.Image_2x} 900w`}
              sizes={`(min-width: 932px) 932px, 100vw`}
            ></img>
          )}
        </div>
        <div className="flex flex-row gap-2 md:gap-8 justify-evenly flex-wrap">
          <img
            className="w-5/12 md:w-1/4 object-contain self-end shadow-lg rounded-lg"
            alt={collagesContent.Collage2Alt}
            src={collagesContent.Collage2}
            srcSet={`
                  ${collagesContent.Collage2} 300w, 
                  ${collagesContent.Collage2_2x} 900w`}
            sizes={`(min-width: 932px) 932px, 100vw`}
          />
          <img
            className="w-5/12 md:w-1/4 object-contain self-end shadow-lg rounded-lg"
            alt={collagesContent.Collage3Alt}
            src={collagesContent.Collage3}
            srcSet={`
                  ${collagesContent.Collage3} 300w, 
                  ${collagesContent.Collage3_2x} 900w`}
            sizes={`(min-width: 932px) 932px, 100vw`}
          />

          <img
            className="w-5/12 md:w-1/4 object-contain self-end  shadow-lg rounded-lg"
            alt={collagesContent.Collage4Alt}
            src={collagesContent.Collage4}
            srcSet={`
                  ${collagesContent.Collage4} 300w, 
                  ${collagesContent.Collage4_2x} 900w`}
            sizes={`(min-width: 932px) 932px, 100vw`}
          />

          <img
            className="w-5/12 md:w-1/4 object-contain self-end  shadow-lg rounded-lg"
            alt={collagesContent.Collage5Alt}
            src={collagesContent.Collage5}
            srcSet={`
                  ${collagesContent.Collage5} 300w, 
                  ${collagesContent.Collage5_2x} 900w`}
            sizes={`(min-width: 932px) 932px, 100vw`}
          />

          <img
            className="w-5/12 md:w-1/4 object-contain self-end  shadow-lg rounded-lg"
            alt={collagesContent.Collage6Alt}
            src={collagesContent.Collage6}
            srcSet={`
                  ${collagesContent.Collage6} 300w, 
                  ${collagesContent.Collage6_2x} 900w`}
            sizes={`(min-width: 932px) 932px, 100vw`}
          />
          <img
            className="w-5/12 md:w-1/4 object-contain self-end  shadow-lg rounded-lg"
            alt={collagesContent.Collage7Alt}
            src={collagesContent.Collage7}
            srcSet={`
                  ${collagesContent.Collage7} 300w, 
                  ${collagesContent.Collage7_2x} 900w`}
            sizes={`(min-width: 932px) 932px, 100vw`}
          />

        </div>
        <div className="flex flex-row gap-2 md:gap-8 pt-2 justify-evenly flex-wrap">
          <img
            className="w-5/12 md:w-2/5 object-contain self-end shadow-lg rounded-lg"
            alt={collagesContent.CollageBig1Alt}
            src={collagesContent.CollageBig1}
            srcSet={`
                  ${collagesContent.CollageBig1} 300w, 
                  ${collagesContent.CollageBig1_2x} 900w`}
            sizes={`(min-width: 932px) 932px, 100vw`}
          />

          <img
            className="w-5/12 md:w-2/5 object-contain self-end shadow-lg rounded-lg"
            alt={collagesContent.CollageBig2Alt}
            src={collagesContent.CollageBig2}
            srcSet={`
              ${collagesContent.CollageBig2} 300w, 
              ${collagesContent.CollageBig2_2x} 900w`}
            sizes={`(min-width: 932px) 932px, 100vw`}
          />

          <img
            className="w-5/12 md:w-2/5 object-contain self-end shadow-lg rounded-lg"
            alt={collagesContent.CollageBig3Alt}
            src={collagesContent.CollageBig3}
            srcSet={`
                ${collagesContent.CollageBig3} 300w, 
                ${collagesContent.CollageBig3_2x} 900w`}
            sizes={`(min-width: 932px) 932px, 100vw`}
          />

          <img
            className="w-5/12 md:w-2/5 object-contain self-end shadow-lg rounded-lg"
            alt={collagesContent.CollageBig4Alt}
            src={collagesContent.CollageBig4}
            srcSet={`
            ${collagesContent.CollageBig4} 300w, 
            ${collagesContent.CollageBig4_2x} 900w`}
            sizes={`(min-width: 932px) 932px, 100vw`}
          />
          <img
            className="w-5/12 md:w-2/5 object-contain self-end shadow-lg rounded-lg"
            alt={collagesContent.CollageBig5Alt}
            src={collagesContent.CollageBig5}
            srcSet={`
          ${collagesContent.CollageBig5} 300w, 
          ${collagesContent.CollageBig5_2x} 900w`}
            sizes={`(min-width: 932px) 932px, 100vw`}
          />

          <img
            className="w-5/12 md:w-2/5 object-contain self-end shadow-lg rounded-lg"
            alt={collagesContent.CollageBig6Alt}
            src={collagesContent.CollageBig6}
            srcSet={`${collagesContent.CollageBig6} 300w, 
              ${collagesContent.CollageBig6_2x} 900w`}
            sizes={`(min-width: 932px) 932px, 100vw`}
          />

          <img
            className="w-5/12 md:w-2/5 object-contain self-end shadow-lg rounded-lg"
            alt={collagesContent.CollageBig7Alt}
            src={collagesContent.CollageBig7}
            srcSet={`
                  ${collagesContent.CollageBig7} 300w, 
                  ${collagesContent.CollageBig7_2x} 900w`}
            sizes={`(min-width: 932px) 932px, 100vw`}
          />

          <img
            className="w-5/12 md:w-2/5 object-contain self-end shadow-lg rounded-lg"
            alt={collagesContent.CollageBig8Alt}
            src={collagesContent.CollageBig8}
            srcSet={`
                ${collagesContent.CollageBig8} 300w, 
                ${collagesContent.CollageBig8_2x} 900w`}
            sizes={`(min-width: 932px) 932px, 100vw`}
          />
        </div>
      </div>
    </>
  );
};

export default Collages;
