import Content from '../content/acerca-de-mi';
import ReturnToTopArrow from './ReturnToTopArrow';

const AboutMe = () => {
	return (
		<div className="flex flex-col lg:flex-row p-8 lg:px-24 lg:py-4 lg:gap-16 lg:flex-row-reverse">
			<div className="">
				<h2 className="my-8">{Content.Title} </h2>
				<section className="content text-justify"
					dangerouslySetInnerHTML={{ __html: Content.Content }}
				></section>
			</div>
			<img className="w-full lg:w-1/3 object-contain" src={Content.Image} alt={Content.AltImage} />
			<ReturnToTopArrow />
		</div>
	);
};

export default AboutMe;
