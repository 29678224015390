import { grupoSIMContent } from '../../content/grupo-sim';

interface GenericProps {
  isMobile: boolean;
}
const OpelBanner = ({ isMobile }: GenericProps) => {
  return (
    <>
      <h3 className="my-8">{grupoSIMContent.subSectionOpelBanner} </h3>
      <div className="flex flex-row gap-2 md:gap-8 pt-2 justify-evenly flex-wrap">
        <img
          className="w-5/12 md:w-2/5 object-contain self-end shadow-lg rounded-lg"
          alt={grupoSIMContent.opelBannerHint}
          src={grupoSIMContent.opelBanner1}
          srcSet={`
                  ${grupoSIMContent.opelBanner1} 300w, 
                  ${grupoSIMContent.opelBanner1_2x} 900w`}
          sizes={`(min-width: 932px) 932px, 100vw`}
        />

        <img
          className="w-5/12 md:w-2/5 object-contain self-end shadow-lg rounded-lg"
          alt={grupoSIMContent.opelBannerHint}
          src={grupoSIMContent.opelBanner2}
          srcSet={`
                  ${grupoSIMContent.opelBanner2} 300w, 
                  ${grupoSIMContent.opelBanner2_2x} 900w`}
          sizes={`(min-width: 932px) 932px, 100vw`}
        />
      </div>
    </>
  );
};

export default OpelBanner;