import { grupoSIMContent } from '../../content/grupo-sim';

interface GenericProps {
  isMobile: boolean;
}
const OpelEmail = ({ isMobile }: GenericProps) => {
  return (
    <>
      <h3 className="my-8">{grupoSIMContent.subSectionOpelEmail} </h3>
      <div className="flex md:flex-row gap-2 md:gap-8 pt-2 justify-evenly flex-wrap">
        <div className="flex md:flex-row justify-evenly flex-wrap">
          <div className="w-1/2 p-2">
            <img
              className="object-contain shadow-lg rounded-lg"
              alt={grupoSIMContent.opelEmailHint}
              src={grupoSIMContent.opelEmail1}
              srcSet={`
                    ${grupoSIMContent.opelEmail1} 300w, 
                    ${grupoSIMContent.opelEmail1_2x} 900w`}
              sizes={`(min-width: 932px) 932px, 100vw`}
            />
          </div>
          <div className="w-1/2 p-2">
          <img
            className="object-contain shadow-lg rounded-lg"
            alt={grupoSIMContent.opelEmailHint}
            src={grupoSIMContent.opelEmail2}
            srcSet={`
                  ${grupoSIMContent.opelEmail2} 300w, 
                  ${grupoSIMContent.opelEmail2_2x} 900w`}
            sizes={`(min-width: 932px) 932px, 100vw`}
          />
          </div>
        </div>

      </div>
    </>
  );
};

export default OpelEmail;