// import { Link } from 'react-router-dom';
import { FC } from 'react';

interface Props {
	color: string;
}
const BurgerButton: FC<Props> = ({ color }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<g id="Grupo_2" data-name="Grupo 2">
				<line
					id="Línea_1"
					data-name="Línea 1"
					x1="2"
					x2="22"
					y1="4"
					y2="4"
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeWidth="5"
				/>
				<line
					id="Línea_2"
					data-name="Línea 2"
					x1="2"
					x2="22"
					y1="12"
					y2="12"
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeWidth="5"
				/>
				<line
					id="Línea_3"
					data-name="Línea 3"
					x1="2"
					x2="22"
					y1="20"
					y2="20"
					fill="none"
					stroke={color}
					strokeLinecap="round"
					strokeWidth="5"
				/>
			</g>
		</svg>
	);
};

export default BurgerButton;
