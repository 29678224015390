import { TwoImageContent, ThreeImageContent } from './Content';

import poster from '../images/editorial/poster.png';
import poster_2x from '../images/editorial/poster@2x.png';
import fanzineExterior from '../images/editorial/fanzine-exterior.png';
import fanzineExterior_2x from '../images/editorial/fanzine-exterior@2x.png';
import fanzine from '../images/editorial/fanzine.png';
import fanzine_2x from '../images/editorial/fanzine@2x.png';
import audio from '../images/editorial/audio.png';
import audio_2x from '../images/editorial/audio@2x.png';
import libro from '../images/editorial/libro.png';
import libro_2x from '../images/editorial/libro@2x.png';
import hardcoverBookMockup1 from '../images/editorial/hardcover-book-mockup-left.png';
import hardcoverBookMockup1_2 from '../images/editorial/hardcover-book-mockup-left@2x.png';
import hardcoverBookMockup2 from '../images/editorial/hardcover-book-mockup-right.png';
import hardcoverBookMockup2_2x from '../images/editorial/hardcover-book-mockup-right@2x.png';
import youtube from '../images/editorial/youtube.png';
import youtube_2x from '../images/editorial/youtube@2x.png';

export interface LibrosImageContent extends TwoImageContent {
  Image3Left: string;
  Image3Left_2x: string,
  AltImage3Left: string;
  Image3Right: string;
  Image3Right_2x: string,
  AltImage3Right: string;
  ImageBottom: string;
  ImageBottom_2x: string,
  AltImageBottom: string;
}

export const PasadoFuturoContent: ThreeImageContent = {
  "Title": "Pasado Futuro",
  "Content": `<p>Pasado futuro es un fanzine de creación propia sobre la tipografía Futura de Paul Renner. En el se puede encontrar toda las características morfológicas de la tipo, así como información práctica de la misma.
	<br/>
	En la maquetación, se puede ver, que la sobre portada, se convierte en un póster.
	</p>`,
  "Image": poster,
  "Image_2x": poster_2x,
  "AltImage": "Poster fanzine",
  Image2: fanzineExterior,
  Image2_2x: fanzineExterior_2x,
  AltImage2: 'exterior fanzine',
  Image3: fanzine,
  Image3_2x: fanzine_2x,
  AltImage3: 'fanzine',
}

export const LibrosContent: LibrosImageContent = {
  "Title": "Libros",
  "Content": `<p>En esta sección podemos ver tanto la creación de portadas de libros así como la de maquetación de los mismo para diferentes libros para el Departamento de Comunicación Audiovisual y Publicidad de la  Universidad de Málaga.
	</p>`,
  "Image": libro,
  "Image_2x": libro_2x,
  "AltImage": 'exterior fanzine',
  "Image2": audio,
  "Image2_2x": audio_2x,
  "AltImage2": "careta",
  "Image3Left": hardcoverBookMockup1,
  "Image3Left_2x": hardcoverBookMockup1_2,
  "AltImage3Left": "...",
  "Image3Right": hardcoverBookMockup2,
  "Image3Right_2x": hardcoverBookMockup2_2x,
  "AltImage3Right": "string",
  "ImageBottom": youtube,
  "ImageBottom_2x": youtube_2x,
  "AltImageBottom": "string",
}


const content = [PasadoFuturoContent, LibrosContent];
export default content;