
import useMedia from 'use-media';
import GrupoSIMComponent  from '../components/grupo-sim/GrupoSIMComponent';
import ReturnToTopArrow from './ReturnToTopArrow';

const GrupoSIM = () => {
  const isMobile = useMedia({ maxWidth: '640px' });
  return (
    <>
      <GrupoSIMComponent isMobile={isMobile} />
      <ReturnToTopArrow />
    </>
  );
};

export default GrupoSIM;
