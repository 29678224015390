import { SimpleContent } from './Content';

import barriga from '../images/photography/montaje-barriga.png';
import barriga_2x from '../images/photography/montaje-barriga@2x.png';

import cabeza from '../images/photography/montaje-cabeza.png';
import cabeza_2x from '../images/photography/montaje-cabeza@2x.png';

import huevos from '../images/photography/montaje-huevos.png';
import huevos_2x from '../images/photography/montaje-huevos@2x.png';

import careta from '../images/photography/montaje-careta.png';
import careta_2x from '../images/photography/montaje-careta@2x.png';

import collage1 from '../images/photography/collage-1.png';
import collage1_2x from '../images/photography/collage-1@2x.png';
import collage2 from '../images/photography/collage-2.png';
import collage2_2x from '../images/photography/collage-2@2x.png';
import collage3 from '../images/photography/collage-3.png';
import collage3_2x from '../images/photography/collage-3@2x.png';
import collage4 from '../images/photography/collage-4.png';
import collage4_2x from '../images/photography/collage-4@2x.png';
import collage5 from '../images/photography/collage-5.png';
import collage5_2x from '../images/photography/collage-5@2x.png';
import collage6 from '../images/photography/collage-6.png';
import collage6_2x from '../images/photography/collage-6@2x.png';
import collage7 from '../images/photography/collage-7.png';
import collage7_2x from '../images/photography/collage-7@2x.png';

import collageBig1 from '../images/photography/collage-big-1.png';
import collageBig1_2x from '../images/photography/collage-big-1@2x.png';
import collageBig2 from '../images/photography/collage-big-2.png';
import collageBig2_2x from '../images/photography/collage-big-2@2x.png';
import collageBig3 from '../images/photography/collage-big-3.png';
import collageBig3_2x from '../images/photography/collage-big-3@2x.png';
import collageBig4 from '../images/photography/collage-big-4.png';
import collageBig4_2x from '../images/photography/collage-big-4@2x.png';
import collageBig5 from '../images/photography/collage-big-5.png';
import collageBig5_2x from '../images/photography/collage-big-5@2x.png';
import collageBig6 from '../images/photography/collage-big-6.png';
import collageBig6_2x from '../images/photography/collage-big-6@2x.png';
import collageBig7 from '../images/photography/collage-big-7.png';
import collageBig7_2x from '../images/photography/collage-big-7@2x.png';
import collageBig8 from '../images/photography/collage-big-8.png';
import collageBig8_2x from '../images/photography/collage-big-8@2x.png';

export interface FotomontajesContent extends SimpleContent {
  // Barriga: string;
  // Barriga_2x: string,
  // BarrigaAlt: string;

  Huevos: string;
  Huevos_2x: string,
  HuevosAlt: string;

  Cabeza: string;
  Cabeza_2x: string,
  CabezaAlt: string;

  Careta: string;
  Careta_2x: string,
  CaretaAlt: string;
}

export interface CollagesContent extends SimpleContent {
  Collage2: string;
  Collage2_2x: string,
  Collage2Alt: string;

  Collage3: string;
  Collage3_2x: string,
  Collage3Alt: string;

  Collage4: string;
  Collage4_2x: string,
  Collage4Alt: string;

  Collage5: string;
  Collage5_2x: string,
  Collage5Alt: string;

  Collage6: string;
  Collage6_2x: string,
  Collage6Alt: string;

  Collage7: string;
  Collage7_2x: string,
  Collage7Alt: string;

  CollageBig1: string;
  CollageBig1_2x: string,
  CollageBig1Alt: string;

  CollageBig2: string;
  CollageBig2_2x: string,
  CollageBig2Alt: string;

  CollageBig3: string;
  CollageBig3_2x: string,
  CollageBig3Alt: string;

  CollageBig4: string;
  CollageBig4_2x: string,
  CollageBig4Alt: string;

  CollageBig5: string;
  CollageBig5_2x: string,
  CollageBig5Alt: string;

  CollageBig6: string;
  CollageBig6_2x: string,
  CollageBig6Alt: string;

  CollageBig7: string;
  CollageBig7_2x: string,
  CollageBig7Alt: string;

  CollageBig8: string;
  CollageBig8_2x: string,
  CollageBig8Alt: string;
}

export const fotomontajesContent: FotomontajesContent = {
  "Title": "Fotomontajes",
  "Content": `<p>Con los fotomontajes que me hacía a mi mismo, es donde empezó mi amor por el mundo del diseño gráfico. Gracias a ello, y a las ganas de aprender más sobre el tema, me matriculé en la Escuela de Arte de Cádiz para completar mi formación.</p>`,
  "Image": barriga,
  "Image_2x": barriga_2x,
  "AltImage": "Fotomontaje fotográfico",

  "Huevos": huevos,
  "Huevos_2x": huevos_2x,
  "HuevosAlt": "otomontaje fotográfico",

  "Cabeza": cabeza,
  "Cabeza_2x": cabeza_2x,
  "CabezaAlt": "otomontaje fotográfico",

  "Careta": careta,
  "Careta_2x": careta_2x,
  "CaretaAlt": "otomontaje fotográfico",
}

export const collagesContent: CollagesContent = {
  "Title": "Collages",
  "Content": `<p>Otra de mis pasiones son los collages digitales. Me ayudan mucho a expresar como me siento en días determinados, o simplemente los hago para pasar el rato.  Para ello, puedo realizarlo tanto con fotografía vintage, como de arquitectura así como usar obras de artes conocidas.</p>
	<p>Se podría decir que es un poco mezcla de todo, o como bien digo en mi perfil de instagram, si mezclamos emociones + dislexia + diseño = collages variopintos.</p>`,
  "Image": collage1,
  "Image_2x": collage1_2x,
  "AltImage": "Collages de Azul de la chus",

  "Collage2": collage2,
  "Collage2_2x": collage2_2x,
  "Collage2Alt": "Collages de Azul de la chus",

  "Collage3": collage3,
  "Collage3_2x": collage3_2x,
  "Collage3Alt": "Collages de Azul de la chus",

  "Collage4": collage4,
  "Collage4_2x": collage4_2x,
  "Collage4Alt": "Collages de Azul de la chus",

  "Collage5": collage5,
  "Collage5_2x": collage5_2x,
  "Collage5Alt": "Collages de Azul de la chus",

  "Collage6": collage6,
  "Collage6_2x": collage6_2x,
  "Collage6Alt": "Collages de Azul de la chus",

  "Collage7": collage7,
  "Collage7_2x": collage7_2x,
  "Collage7Alt": "Collages de Azul de la chus",

  "CollageBig1": collageBig1,
  "CollageBig1_2x": collageBig1_2x,
  "CollageBig1Alt": "Collages arquitectonicos de los diferentes barrios de Cádiz",
  "CollageBig2": collageBig2,
  "CollageBig2_2x": collageBig2_2x,
  "CollageBig2Alt": "Collages arquitectonicos de los diferentes barrios de Cádiz",
  "CollageBig3": collageBig3,
  "CollageBig3_2x": collageBig3_2x,
  "CollageBig3Alt": "Collages arquitectonicos de los diferentes barrios de Cádiz",
  "CollageBig4": collageBig4,
  "CollageBig4_2x": collageBig4_2x,
  "CollageBig4Alt": "Collages arquitectonicos de los diferentes barrios de Cádiz",

  "CollageBig5": collageBig5,
  "CollageBig5_2x": collageBig5_2x,
  "CollageBig5Alt": "Intervenciones artísticas de cuadros de antaño",

  "CollageBig6": collageBig6,
  "CollageBig6_2x": collageBig6_2x,
  "CollageBig6Alt": "Intervenciones artísticas de cuadros de antaño",

  "CollageBig7": collageBig7,
  "CollageBig7_2x": collageBig7_2x,
  "CollageBig7Alt": "Intervenciones artísticas de cuadros de antaño",

  "CollageBig8": collageBig8,
  "CollageBig8_2x": collageBig8_2x,
  "CollageBig8Alt": "Intervenciones artísticas de cuadros de antaño",
}

const content = [fotomontajesContent, collagesContent]
export default content;

