import { useState } from 'react';

const ReturnToTopArrow = () => {
	const [showArrow, setShowArrow] = useState(false);

	const checkScroll = () => {
		const el = document.getElementById('main');

		if (el) {
			if (!showArrow && el.scrollTop > 400) {
				setShowArrow(true);
			} else if (showArrow && el.scrollTop <= 400) {
				setShowArrow(false);								
			}
		}
	};

	const scrollToTop = () => {
		const el = document.getElementById('main');
		if (el) {
			el.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	const el = document.getElementById('main');
	if (el) el.addEventListener('scroll', checkScroll);

	const arrow = document.getElementById('arrow');
	if (arrow) 	arrow.addEventListener("animationend", ()=> {

	},false);

	const opacity = showArrow? "opacity-1 visible" : "opacity-0 invisible";
	return (
		<div id="arrow"
			className={`p-4 cursor-pointer absolute bottom-2 right-4 
			animate-bounce
			transition duration-1000 easy-in-out ${opacity}`}
			onClick={scrollToTop}
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="28.562"
				height="18.281"
				viewBox="0 0 28.562 18.281"
			>
				<path
					id="Path_16"
					data-name="Path 16"
					d="M-238.462,816.154l8.624,8.624-8.624,8.624"
					transform="translate(-810.497 -225.837) rotate(-90)"
					fill="none"
					stroke="#0e1d50"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="8"
				/>
			</svg>
		</div>
	);
};

export default ReturnToTopArrow;
