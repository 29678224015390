
import { octopusContent } from '../../content/branding';

interface OctopusProps {
  isMobile: boolean;
}

const Octopus = ({ isMobile }: OctopusProps) => {
  return (
    <>
      <div className="flex flex-col md:flex-col gap-4 md:gap-16">
        <div className="flex flex-col md:flex-row gap-4 md:justify-between">
          <div className="md:w-2/3">
            <h2 className="my-8">{octopusContent.Title} </h2>
            <section
              className="content text-justify"
              dangerouslySetInnerHTML={{
                __html: octopusContent.Content,
              }}
            ></section>
          </div>
          <img
            className="w-full md:w-auto object-contain self-end md:max-h-80 md:max-w-2xl"

            alt={octopusContent.AltImage}
            src={octopusContent.Image}
            srcSet={`
                  ${octopusContent.Image} 300w, 
                  ${octopusContent.Image_2x} 900w`}
            sizes={`(min-width: 932px) 932px, 100vw`}
          ></img>
        </div>
        <div className="flex flex-col md:flex-row gap-4 md:justify-between">
          <img
            className="w-full md:w-auto object-contain self-end md:max-h-80 md:max-w-2xl shadow-lg rounded-lg"

            alt={octopusContent.OctopusBookAlt}
            src={octopusContent.OctopusBook}
            srcSet={`
                  ${octopusContent.OctopusBook} 300w, 
                  ${octopusContent.OctopusBook_2x} 900w`}
            sizes={`(min-width: 932px) 932px, 100vw`}
          ></img>
          <img
            className="w-full md:w-auto object-contain self-end md:max-h-80 md:max-w-2xl shadow-lg rounded-lg"

            alt={octopusContent.OctopusBrochureAlt}
            src={octopusContent.OctopusBrochure}
            srcSet={`
                  ${octopusContent.OctopusBrochure} 300w, 
                  ${octopusContent.OctopusBrochure_2x} 900w`}
            sizes={`(min-width: 932px) 932px, 100vw`}
          ></img>
        </div>
      </div>
    </>
  );
};

export default Octopus;
