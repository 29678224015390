
import { PasadoFuturoContent } from '../../content/editorial';

interface PasadoFuturoProps {
	isMobile: boolean;
}

const PasadoFuturo = ({isMobile}: PasadoFuturoProps) => {
	return (
    <div className="flex flex-col p-8 gap-4 md:px-24 md:pt-4 md:pb-8 md:gap-16">
        <div className="flex flex-col lg:flex-row lg:gap-16">
          <div className="flex flex-col lg:flex-col lg:gap-4 md:w-2/3">
            <div className="">
              <h2 className="my-8">{PasadoFuturoContent.Title} </h2>
              <section
                className="content text-justify"
                dangerouslySetInnerHTML={{
                  __html: PasadoFuturoContent.Content,
                }}
              ></section>
            </div>

            {isMobile && (
              <img
                className="w-full mb-4 md:mb-0 lg:w-1/3 object-contain self-end shadow-lg rounded-lg"
                style={{ maxWidth: '394px', maxHeight: '550px' }}
                alt="Filosofía del paseante"
                src={PasadoFuturoContent.Image}
                srcSet={`
                  ${PasadoFuturoContent.Image} 300w, 
                  ${PasadoFuturoContent.Image_2x} 900w`}
                sizes={`
                  (min-width: 932px) 932px, 100vw
                `}
              ></img>
            )}

            <img
              className="object-cover justify-end lg:self-center shadow-lg rounded-lg"
              style={{ maxWidth: '714px', maxHeight: '337px' }}
              src={PasadoFuturoContent.Image2}
              srcSet={`
                  ${PasadoFuturoContent.Image2} 300w, 
                  ${PasadoFuturoContent.Image2_2x} 900w`}
              sizes={`
                  (min-width: 932px) 932px, 100vw
                `}
              alt={PasadoFuturoContent.AltImage2}
            />
          </div>
          {!isMobile && (
            <img
              className="w-full lg:w-1/3 object-contain self-end shadow-lg rounded-lg"
              style={{ maxWidth: '394px', maxHeight: '550px' }}
              alt="Filosofía del paseante"
              src={PasadoFuturoContent.Image}
              srcSet={`
                  ${PasadoFuturoContent.Image} 300w, 
                  ${PasadoFuturoContent.Image_2x} 900w`}
              sizes={`
                  (min-width: 932px) 932px, 100vw
                `}
            ></img>
          )}

        </div>
        <img
          className="object-contain shadow-lg rounded-lg md:self-center md:max-h-80 md:max-w-3xl"
          alt={PasadoFuturoContent.AltImage3}
          src={PasadoFuturoContent.Image3}
          srcSet={`
                  ${PasadoFuturoContent.Image3} 300w, 
                  ${PasadoFuturoContent.Image3_2x} 900w`}
          sizes={`
                  (min-width: 932px) 932px, 100vw
                `}
        ></img>
      </div>
	);
};

export default PasadoFuturo;
