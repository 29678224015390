import useMedia from 'use-media';
import ReturnToTopArrow from './ReturnToTopArrow'

import Libros from './editorial/Libros';
import PasadoFuturo from './editorial/PasadoFuturo';

const Editorial = () => {
  const isMobile = useMedia({ maxWidth: '640px' });
  return (
    <>
      <PasadoFuturo isMobile={isMobile} />
      <Libros isMobile={isMobile} />
      <ReturnToTopArrow />
    </>
  );
};

export default Editorial;
