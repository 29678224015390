
import { grupoSIMContent } from '../../content/grupo-sim';
import FiNetworkPLV from './FiNetworkPLV';
import OpelBanner from './OpelBanner';
import OpelEmail from './OpelEmail';
import OpelGuias from './OpelGuias';
import OpelPLV from './OpelPLV';

interface GenericProps {
  isMobile: boolean;
}

const GrupoSIMComponent = ({ isMobile }: GenericProps) => {
  return (
    <>
      <div className="flex flex-col p-8 md:px-24 md:pt-4 md:pb-8">
        <h2 className="my-8 text-4xl">{grupoSIMContent.Title} </h2>
        <div className="flex flex-col gap-8 md:flex-col md:gap-16">
          <div className="">
            <section
              className="content text-justify"
              dangerouslySetInnerHTML={{
                __html: grupoSIMContent.Content,
              }}
            ></section>
          </div>
          <div className="flex flex-col p-6 gap-8 md:flex-row center md:justify-between">
            <img
              className="w-full mb-4 md:mb-0 md:w-1/3 object-contain "
              alt={grupoSIMContent.logoOpelHint}
              src={grupoSIMContent.logoOpel}
              srcSet={`
                  ${grupoSIMContent.logoOpel} 300w, 
                  ${grupoSIMContent.logoOpel_2x} 900w`}
              sizes={`
                  (min-width: 932px) 932px, 100vw
                `}
            />
            <img
              className="w-full mb-4 md:mb-0 md:w-1/3 object-contain"
              alt={grupoSIMContent.logoFinetworkHint}
              src={grupoSIMContent.logoFinetwork}
              srcSet={`
                  ${grupoSIMContent.logoFinetwork} 300w, 
                  ${grupoSIMContent.logoFinetwork_2x} 900w`}
              sizes={`
                  (min-width: 932px) 932px, 100vw
                `}
            />
          </div>

          <div className="flex flex-col p-6 gap-8 md:flex-row center md:justify-between">
            <img
              className="w-full mb-4 md:mb-0 md:w-1/3 object-contain  "
              alt={grupoSIMContent.logoALDHint}
              src={grupoSIMContent.logoALD}
              srcSet={`
                  ${grupoSIMContent.logoALD} 300w, 
                  ${grupoSIMContent.logoALD_2x} 900w`}
              sizes={`
                  (min-width: 932px) 932px, 100vw
                `}
            />
            <img
              className="w-full mb-4 md:mb-0 md:w-1/3 object-contain"
              alt={grupoSIMContent.logoSTIHLHint}
              src={grupoSIMContent.logoSTIHL}
              srcSet={`
                  ${grupoSIMContent.logoSTIHL} 300w, 
                  ${grupoSIMContent.logoSTIHL_2x} 900w`}
              sizes={`
                  (min-width: 932px) 932px, 100vw
                `}
            />
          </div>

          <div className="">
            <h2 className="my-8 text-4xl">{grupoSIMContent.sectionOpel} </h2>
            <div className="ml-12">
              <OpelGuias isMobile={isMobile} />
              <OpelPLV isMobile={isMobile} />
              <OpelBanner isMobile={isMobile} />
              <OpelEmail isMobile={isMobile} />
            </div>
          </div>

          <div className="">
            <h2 className="my-8 text-4xl">{grupoSIMContent.sectionFinetwork} </h2>
            <div className="ml-12">
              <FiNetworkPLV isMobile={isMobile} />
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default GrupoSIMComponent;
