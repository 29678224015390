import { SimpleContent } from './Content';

import tauteo from '../images/branding/tauteo.png';
import tauteo_2x from '../images/branding/tauteo@2x.png';
import tauteoBook from '../images/branding/tauteo-book.png';
import tauteoBook_2x from '../images/branding/tauteo-book@2x.png';

import sample1 from '../images/branding/sample-1.png';
import sample1_2x from '../images/branding/sample-1@2x.png';
import sample2 from '../images/branding/sample-2.png';
import sample2_2x from '../images/branding/sample-2@2x.png';
import sample3 from '../images/branding/sample-3.png';
import sample3_2x from '../images/branding/sample-3@2x.png';
import sample4 from '../images/branding/sample-4.png';
import sample4_2x from '../images/branding/sample-4@2x.png';

import tradicion1 from '../images/branding/tradicion-1.png';
import tradicion1_2x from '../images/branding/tradicion-1@2x.png';
import tradicion2 from '../images/branding/tradicion-2.png';
import tradicion2_2x from '../images/branding/tradicion-2@2x.png';
import tradicion3 from '../images/branding/tradicion-3.png';
import tradicion3_2x from '../images/branding/tradicion-3@2x.png';
import tradicion4 from '../images/branding/tradicion-4.png';
import tradicion4_2x from '../images/branding/tradicion-4@2x.png';

import octopusLogo from '../images/branding/octopus-logo.png';
import octopusLogo_2x from '../images/branding/octopus-logo@2x.png';
import octopusBook from '../images/branding/octopus-book.png';
import octopusBook_2x from '../images/branding/octopus-book@2x.png';
import octopusBrochure from '../images/branding/sample-brochure.png';
import octopusBrochure_2x from '../images/branding/sample-brochure@2x.png';


export interface TauteoContent extends SimpleContent {
  TauteoBook: string;
  TauteoBook_2x: string,
  TauteoBookAlt: string;

  Sample1: string;
  Sample1_2x: string,
  Sample1Alt: string;

  Sample2: string;
  Sample2_2x: string,
  Sample2Alt: string;

  Sample3: string;
  Sample3_2x: string,
  Sample3Alt: string;

  Sample4: string;
  Sample4_2x: string,
  Sample4Alt: string;

  Tradicion1: string;
  Tradicion1_2x: string,
  Tradicion1Alt: string;

  Tradicion2: string;
  Tradicion2_2x: string,
  Tradicion2Alt: string;

  Tradicion3: string;
  Tradicion3_2x: string,
  Tradicion3Alt: string;

  Tradicion4: string;
  Tradicion4_2x: string,
  Tradicion4Alt: string;
}

export interface OctopusContent extends SimpleContent {
  OctopusBook: string;
  OctopusBook_2x: string,
  OctopusBookAlt: string;
 
  OctopusBrochure: string;
  OctopusBrochure_2x: string,
  OctopusBrochureAlt: string;
}

export const tauteoContent: TauteoContent = {
  "Title": "Tauteo",
  "Content": `<p>Se trata de una empresa familiar de Aceite de Oliva Virgen extra, especializada en productos gourmet. La cual quieren una actualización de la marca, ya que pasa a estar a cargo de la nueva generación de la familia. Para ello, se realizó una actualización de la marca dándole un carácter actual, pero que al mismo tiempo, se reflejara la trayectoria que tiene, es decir, la tradición.</p>
	<p>Por lo que en este caso, se hizo desde el logotipo, el packaging, hasta publicidad.</p>`,
  "Image": tauteo,
  "Image_2x": tauteo_2x,
  "AltImage": "Logotipo de Tauteto, aceite de oliva virgen extra",

  TauteoBook: tauteoBook,
  TauteoBook_2x: tauteoBook_2x,
  TauteoBookAlt: "Manual de identidad de la marca Tauteto",

  Sample1: sample1,
  Sample1_2x: sample1_2x,
  Sample1Alt: "Packaging de las botellas de aceite tauteo",

  Sample2: sample2,
  Sample2_2x: sample2_2x,
  Sample2Alt: "Packaging de las botellas de aceite tauteo",

  Sample3: sample3,
  Sample3_2x: sample3_2x,
  Sample3Alt: "Packaging de las botellas de aceite tauteo",

  Sample4: sample4,
  Sample4_2x: sample4_2x,
  Sample4Alt: "Packaging de las botellas de aceite tauteo",

  Tradicion1: tradicion1,
  Tradicion1_2x: tradicion1_2x,
  Tradicion1Alt: "Carteleria publicitaria del aceite tauteo",

  Tradicion2: tradicion2,
  Tradicion2_2x: tradicion2_2x,
  Tradicion2Alt: "Carteleria publicitaria del aceite tauteo",

  Tradicion3: tradicion3,
  Tradicion3_2x: tradicion3_2x,
  Tradicion3Alt: "Carteleria publicitaria del aceite tauteo",

  Tradicion4: tradicion4,
  Tradicion4_2x: tradicion4_2x,
  Tradicion4Alt: "Carteleria publicitaria del aceite tauteo",
}

export const octopusContent: OctopusContent = {
  "Title": "Octopus",
  "Content": `<p>Octopus se trata de la creación de la marca de una editorial de nueva creación para la reedición de los grandes clásicos de la literatura.</p>
	<p>Para nos inspiramos en la mancha  que dejaría una gota de tinta sobre el papel, además de utilizar una tipografía que simulara a la de las máquinas de escribir.</p>
  <p>Para las primeras ediciones de los grandes clásicos, se optó, por realizar unas portadas sencillas, con manchas de acuarelas además de una tipografía desenfada.</p>`,
  "Image": octopusLogo,
  "Image_2x": octopusLogo_2x,
  "AltImage": "Logotipo de la editorial Octopus.",

  "OctopusBook": octopusBook,
  "OctopusBook_2x": octopusBook_2x,
  "OctopusBookAlt": "Diseño de portada y maquetación de grandes clásicos de la literatura para la editorial octopus",

  "OctopusBrochure": octopusBrochure,
  "OctopusBrochure_2x": octopusBrochure_2x,
  "OctopusBrochureAlt": "Manual de identidad de la marca Octopus editorial"
}

const content = [tauteoContent, octopusContent]
export default content;

