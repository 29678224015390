import { SimpleContent } from './Content';

import mapaCulturalCadiz from '../images/mapa-cultural-cadiz.png';
import mapaCulturalCadiz2x from '../images/mapa-cultural-cadiz@2x.png';

import mapa from '../images/mapa.png';
import mapa_2x from '../images/mapa@2x.png';

import icono1 from '../images/icono-1.png';
import icono1_2x from '../images/icono-1@2x.png';

import icono2 from '../images/icono-2.png';
import icono2_2x from '../images/icono-2@2x.png';

import icono3 from '../images/icono-3.png';
import icono3_2x from '../images/icono-3@2x.png';

import icono4 from '../images/icono-4.png';
import icono4_2x from '../images/icono-4@2x.png';

import icono5 from '../images/icono-5.png';
import icono5_2x from '../images/icono-5@2x.png';

import booklet from '../images/cmc-portada.png';
import booklet_2x from '../images/cmc-portada@2x.png';

import bookletExterior from '../images/cmc-exterior.png';
import bookletExterior_2x from '../images/cmc-exterior@2x.png';

import sample from '../images/sample1.png';
import sample_2x from '../images/sample1@2x.png';

import sample2 from '../images/sample2.png';
import sample2_2x from '../images/sample2@2x.png';

export interface CMCProjectContent extends SimpleContent {
	Mapa: string;
	Mapa_2x: string;
	AltMapa: string;

	Iconos: string[];
	Iconos_2x: string[];

	Booklet: string;
	Booklet_2x: string;
	AltBooklet: string;

	BookletExterior: string;
	BookletExterior_2x: string,
	AltBookletExterior: string;

	// BookletInterior: string;
	// BookletInterior_2x: string,
	// AltBookletInterior: string;

	Sample1: string;
	Sample1_2x: string,
	AltSample1: string;

	Sample2: string;
	Sample2_2x: string,
	AltSample2: string;
}
export const CulturalMapaCadizProyecto: CMCProjectContent = {
	Title: 'Cultura Mapa Cádiz',
	Content: `<p>Cultura Mapa Cádiz, se trata de un proyecto personal, en el cual he creado una guía cultural de la Provincia de Cádiz, en el que se expone tanto los museos como  se han clasificado en 5 categorías (arqueológico, arte moderno, salas mix, galerías de arte y etnográficos), así como  en las diferentes comarcas de la provincia.</p>
    <p>Lo principal de este proyecto, fue la creación del catálogo que alberga todos los museos y salas. Para ello, las imágenes fueron tratadas para darla uniformidad,  tanto en los colores utilizados como en el grafismo usado. Además de la guía, también se hizo diferente elementos tales como cartelería, “pasaporte” de visita de cada museo, dípticos, muppis, creación de una mascota,  publicidad en redes sociales, banner,  diseño de web así como de app, etc.</p>`,
	Image: mapaCulturalCadiz,
	Image_2x: mapaCulturalCadiz2x,
	AltImage: 'Logotipo Cultura Mapa Cádiz ',

	Mapa: mapa,
	Mapa_2x: mapa_2x,
	AltMapa: 'Mapa Cultural Cádiz',

	Iconos: [icono1, icono2, icono3, icono4, icono5],
	Iconos_2x: [icono1_2x, icono2_2x, icono3_2x, icono4_2x, icono5_2x],

	Booklet: booklet,
	Booklet_2x: booklet_2x,
	AltBooklet: 'booklet',

	BookletExterior: bookletExterior,
	BookletExterior_2x: bookletExterior_2x,
	AltBookletExterior: "Guía abierta",

	Sample1: sample,
	Sample1_2x: sample_2x,
	AltSample1: '',

	Sample2: sample2,
	Sample2_2x: sample2_2x,
	AltSample2: ''
};

export default CulturalMapaCadizProyecto;
