
import { LibrosContent } from '../../content/editorial';

interface LibrosProps {
  isMobile: boolean;
}

const Libros = ({ isMobile }: LibrosProps) => {
  return (
    <div className="flex flex-col p-8 gap-4 md:px-24 md:pt-4 md:pb-8 md:gap-16">
      <div className="flex flex-col lg:flex-row lg:gap-16">
        <div className="flex flex-col lg:flex-col lg:gap-4 md:w-2/3">
          <div className="">
            <h2 className="my-8">{LibrosContent.Title} </h2>
            <section
              className="content text-justify"
              dangerouslySetInnerHTML={{
                __html: LibrosContent.Content,
              }}
            ></section>
          </div>

          {isMobile && (
            <img
              className="w-full mb-4 md:mb-0 lg:w-1/3 object-contain self-end shadow-lg rounded-lg"
              style={{ maxWidth: '394px', maxHeight: '550px' }}
              alt="Filosofía del paseante"
              src={LibrosContent.Image}
              srcSet={`
                  ${LibrosContent.Image} 300w, 
                  ${LibrosContent.Image_2x} 900w`}
              sizes={`
                  (min-width: 932px) 932px, 100vw
                `}
            ></img>
          )}

          <img
            className="object-cover justify-end lg:self-center shadow-lg rounded-lg"
            style={{ maxWidth: '714px', maxHeight: '337px' }}
            src={LibrosContent.Image2}
            srcSet={`
                  ${LibrosContent.Image2} 300w, 
                  ${LibrosContent.Image2_2x} 900w`}
            sizes={`
                  (min-width: 932px) 932px, 100vw
                `}
            alt={LibrosContent.AltImage2}
          />
        </div>
        {!isMobile && (
          <img
            className="w-full lg:w-1/3 object-contain self-end shadow-lg rounded-lg"
            style={{ maxWidth: '394px', maxHeight: '550px' }}
            alt="Filosofía del paseante"
            src={LibrosContent.Image}
            srcSet={`
                  ${LibrosContent.Image} 300w, 
                  ${LibrosContent.Image_2x} 900w`}
            sizes={`
                  (min-width: 932px) 932px, 100vw
                `}
          ></img>
        )}

      </div>
      <div className="flex flex-col gap-4 md:flex-row md:justify-between">
        <img
          className="w-full object-contain self-end shadow-md rounded-md md:w-auto md:max-h-80 md:max-w-3xl"
          alt={LibrosContent.AltImage3Left}
          src={LibrosContent.Image3Left}
          srcSet={`
                  ${LibrosContent.Image3Left} 300w, 
                  ${LibrosContent.Image3Left_2x} 900w`}
          sizes={`
                  (min-width: 932px) 932px, 100vw
                `}
        ></img>
        <img
          className="w-full object-contain self-end shadow-md rounded-md md:w-auto md:max-h-80 md:max-w-3xl"
          alt={LibrosContent.AltImage3Right}
          src={LibrosContent.Image3Right}
          srcSet={`
                  ${LibrosContent.Image3Right} 300w, 
                  ${LibrosContent.Image3Right_2x} 900w`}
          sizes={`
                  (min-width: 932px) 932px, 100vw
                `}
        ></img>
      </div>
      <img
        className="object-contain shadow-lg rounded-lg md:self-center md:max-h-80 md:max-w-3xl"
        alt={LibrosContent.AltImageBottom}
        src={LibrosContent.ImageBottom}
        srcSet={`
                  ${LibrosContent.ImageBottom} 300w, 
                  ${LibrosContent.ImageBottom_2x} 900w`}
        sizes={`
                  (min-width: 932px) 932px, 100vw
                `}
      ></img>
    </div>
  );
};

export default Libros;
