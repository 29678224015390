import Content from '../content/proyectos';
import useMedia from 'use-media';

import CulturaMapaCadiz from './projects/CulturaMapaCadiz';
import ReturnToTopArrow from './ReturnToTopArrow';

const Projects = () => {
	const isMobile = useMedia({maxWidth: '640px'});
	return (
		<>
			{Content.map((section, index) => {
				return (
					<div
						key={index}
						className="flex flex-col md:flex-row p-8 md:px-24 md:pt-4 md:pb-8 md:gap-16"
					>
						<div className="flex flex-col md:flex-col md:gap-4 md:w-2/3">
							<div className="">
								<h2 className="my-8">{section.Title} </h2>
								<section
									className="content text-justify"
									dangerouslySetInnerHTML={{
										__html: section.Content,
									}}
								></section>
							</div>

							{isMobile && (
								<img
									className="w-full mb-4 md:mb-0 md:w-1/3 object-contain self-end shadow-lg rounded-lg"
									style={{ maxWidth: '394px', maxHeight: '550px' }}
									alt="Filosofía del paseante"
									src={section.Image}
									srcSet={`
                  ${section.Image} 300w, 
                  ${section.Image_2x} 900w`}
									sizes={`
                  (min-width: 932px) 932px, 100vw
                `}
								></img>
							)}

							<img
								className="object-cover justify-end md:self-center shadow-lg rounded-lg"
								style={{ maxWidth: '714px', maxHeight: '337px' }}
								src={section.Image2}
								srcSet={`
                  ${section.Image2} 300w, 
                  ${section.Image2_2x} 900w`}
								sizes={`
                  (min-width: 932px) 932px, 100vw
                `}
								alt={section.AltImage2}
							/>
						</div>
						{!isMobile && (
							<img
								className="w-full md:w-1/3 object-contain self-end shadow-lg rounded-lg"
								style={{ maxWidth: '394px', maxHeight: '550px' }}
								alt="Filosofía del paseante"
								src={section.Image}
								srcSet={`
                  ${section.Image} 300w, 
                  ${section.Image_2x} 900w`}
								sizes={`
                  (min-width: 932px) 932px, 100vw
                `}
							></img>
						)}
					</div>
				);
			})}

			<CulturaMapaCadiz isMobile={isMobile} />
			<ReturnToTopArrow />
		</>
	);
};

export default Projects;
