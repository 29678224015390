import { grupoSIMContent } from '../../content/grupo-sim';

interface GenericProps {
  isMobile: boolean;
}
const FiNetworkPLV = ({ isMobile }: GenericProps) => {
  return (
    <>
      <h3 className="my-8">{grupoSIMContent.subSectionFinetworkMaterialPLV} </h3>
      <div className="flex md:flex-row gap-2 md:gap-8 pt-2 justify-evenly flex-wrap">
        <img
          className="object-contain shadow-lg rounded-lg"
          alt={grupoSIMContent.fiPlvHint}
          src={grupoSIMContent.fiPlv1}
          srcSet={`
                    ${grupoSIMContent.fiPlv1} 300w, 
                    ${grupoSIMContent.fiPlv1_2x} 900w`}
          sizes={`(min-width: 932px) 932px, 100vw`}
        />
        <div className="flex md:flex-row justify-evenly flex-wrap">
          <div className="w-1/2 p-2">
            <img
              className="object-contain shadow-lg rounded-lg"
              alt={grupoSIMContent.fiPlvHint}
              src={grupoSIMContent.fiPlv2}
              srcSet={`
                    ${grupoSIMContent.fiPlv2} 300w, 
                    ${grupoSIMContent.fiPlv2_2x} 900w`}
              sizes={`(min-width: 932px) 932px, 100vw`}
            />
          </div>
          <div className="w-1/2 p-2">
            <img
              className="object-contain shadow-lg rounded-lg"
              alt={grupoSIMContent.fiPlvHint}
              src={grupoSIMContent.fiPlv3}
              srcSet={`
                  ${grupoSIMContent.fiPlv3} 300w, 
                  ${grupoSIMContent.fiPlv3_2x} 900w`}
              sizes={`(min-width: 932px) 932px, 100vw`}
            />
          </div>
        </div>
        <div className="flex md:flex-row justify-evenly flex-wrap">
          <div className="w-1/2 p-2">
            <img
              className="object-contain shadow-lg rounded-lg"
              alt={grupoSIMContent.fiPlvHint}
              src={grupoSIMContent.fiPlv4}
              srcSet={`
                    ${grupoSIMContent.fiPlv4} 300w, 
                    ${grupoSIMContent.fiPlv4_2x} 900w`}
              sizes={`(min-width: 932px) 932px, 100vw`}
            />
          </div>
          <div className="w-1/2 p-2">
          <img
            className="object-contain shadow-lg rounded-lg"
            alt={grupoSIMContent.fiPlvHint}
            src={grupoSIMContent.fiPlv5}
            srcSet={`
                  ${grupoSIMContent.fiPlv5} 300w, 
                  ${grupoSIMContent.fiPlv5_2x} 900w`}
            sizes={`(min-width: 932px) 932px, 100vw`}
          />
          </div>
        </div>
        <img
          className="object-contain shadow-lg rounded-lg"
          alt={grupoSIMContent.fiPlvHint}
          src={grupoSIMContent.fiPlv6}
          srcSet={`
                    ${grupoSIMContent.fiPlv6} 300w, 
                    ${grupoSIMContent.fiPlv6_2x} 900w`}
          sizes={`(min-width: 932px) 932px, 100vw`}
        />
      </div>
    </>
  );
};

export default FiNetworkPLV;