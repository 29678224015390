import { Content } from './Content';

import logoOpel from '../images/grupo-sim/logo-opel.png';
import logoOpel_2x from '../images/grupo-sim/logo-opel@2x.png';

import logoFinetwork from '../images/grupo-sim/logo-finetwork.png';
import logoFinetwork_2x from '../images/grupo-sim/logo-finetwork@2x.png';

import logoALD from '../images/grupo-sim/logo-alda.png';
import logoALD_2x from '../images/grupo-sim/logo-alda@2x.png';

import logoSTIHL from '../images/grupo-sim/logo-stihl.png';
import logoSTIHL_2x from '../images/grupo-sim/logo-stihl@2x.png';

import opelGuias1 from '../images/grupo-sim/opel-guia-1.png';
import opelGuias1_2x from '../images/grupo-sim/opel-guia-1@2x.png';
import opelGuias2 from '../images/grupo-sim/opel-guia-2.png';
import opelGuias2_2x from '../images/grupo-sim/opel-guia-2@2x.png';
import opelGuias3 from '../images/grupo-sim/opel-guia-3.png';
import opelGuias3_2x from '../images/grupo-sim/opel-guia-3@2x.png';

import opelPlv1 from '../images/grupo-sim/opel-plv-1.png';
import opelPlv1_2x from '../images/grupo-sim/opel-plv-1@2x.png';
import opelPlv2 from '../images/grupo-sim/opel-plv-2.png';
import opelPlv2_2x from '../images/grupo-sim/opel-plv-2@2x.png';
import opelPlv3 from '../images/grupo-sim/opel-plv-3.png';
import opelPlv3_2x from '../images/grupo-sim/opel-plv-3@2x.png';
import opelPlv4 from '../images/grupo-sim/opel-plv-4.png';
import opelPlv4_2x from '../images/grupo-sim/opel-plv-4@2x.png';

import opelBanner1 from '../images/grupo-sim/opel-banner-1.png';
import opelBanner1_2x from '../images/grupo-sim/opel-banner-1@2x.png';
import opelBanner2 from '../images/grupo-sim/opel-banner-2.png';
import opelBanner2_2x from '../images/grupo-sim/opel-banner-2@2x.png';

import opelEmail1 from '../images/grupo-sim/opel-email-1.png';
import opelEmail1_2x from '../images/grupo-sim/opel-email-1@2x.png';
import opelEmail2 from '../images/grupo-sim/opel-email-2.png';
import opelEmail2_2x from '../images/grupo-sim/opel-email-2@2x.png';
import opelEmail3 from '../images/grupo-sim/opel-email-3.png';
import opelEmail3_2x from '../images/grupo-sim/opel-email-3@2x.png';
import opelEmail4 from '../images/grupo-sim/opel-email-4.png';
import opelEmail4_2x from '../images/grupo-sim/opel-email-4@2x.png';

import fiPlv1 from '../images/grupo-sim/fi-plv-1.png';
import fiPlv1_2x from '../images/grupo-sim/fi-plv-1@2x.png';
import fiPlv2 from '../images/grupo-sim/fi-plv-2.png';
import fiPlv2_2x from '../images/grupo-sim/fi-plv-2@2x.png';
import fiPlv3 from '../images/grupo-sim/fi-plv-3.png';
import fiPlv3_2x from '../images/grupo-sim/fi-plv-3@2x.png';
import fiPlv4 from '../images/grupo-sim/fi-plv-4.png';
import fiPlv4_2x from '../images/grupo-sim/fi-plv-4@2x.png';
import fiPlv5 from '../images/grupo-sim/fi-plv-5.png';
import fiPlv5_2x from '../images/grupo-sim/fi-plv-5@2x.png';
import fiPlv6 from '../images/grupo-sim/fi-plv-6.png';
import fiPlv6_2x from '../images/grupo-sim/fi-plv-6@2x.png';

export interface GrupoSIMContent extends Content {
  logoOpel: string;
  logoOpel_2x: string,
  logoOpelHint: string;

  logoFinetwork: string;
  logoFinetwork_2x: string,
  logoFinetworkHint: string;

  logoALD: string;
  logoALD_2x: string,
  logoALDHint: string;

  logoSTIHL: string;
  logoSTIHL_2x: string,
  logoSTIHLHint: string;

  sectionOpel: string;
  subSectionOpelGuias: string;
  subSectionOpelMaterialPLV: string;
  subSectionOpelBanner: string;
  subSectionOpelEmail: string;
  sectionFinetwork: string;
  subSectionFinetworkMaterialPLV: string;


  opelGuias1: string;
  opelGuias1_2x: string;

  opelGuias2: string;
  opelGuias2_2x: string;

  opelGuias3: string;
  opelGuias3_2x: string;

  opelPlv1: string;
  opelPlv1_2x: string;

  opelPlv2: string;
  opelPlv2_2x: string;

  opelPlv3: string;
  opelPlv3_2x: string;

  opelPlv4: string;
  opelPlv4_2x: string;

  opelBanner1: string;
  opelBanner1_2x: string;
  opelBanner2: string;
  opelBanner2_2x: string;
  opelBannerHint: string;

  opelEmail1: string;
  opelEmail1_2x: string;
  opelEmail2: string;
  opelEmail2_2x: string;
  opelEmail3: string;
  opelEmail3_2x: string;
  opelEmail4: string;
  opelEmail4_2x: string;
  opelEmailHint: string;

  fiPlv1: string;
  fiPlv1_2x: string;
  fiPlv2: string;
  fiPlv2_2x: string;
  fiPlv3: string;
  fiPlv3_2x: string;
  fiPlv4: string;
  fiPlv4_2x: string;
  fiPlv5: string;
  fiPlv5_2x: string;
  fiPlv6: string;
  fiPlv6_2x: string;
  fiPlvHint: string;

}


export const grupoSIMContent: GrupoSIMContent = {
  "Title": "Grupo SIM",
  "Content": `<p>Actualmente estoy trabajando como Director de Arte en Grupo SIM, que es una agencia especializada en soluciones digitales de publicidad y marketing. La cual trabaja principalmente para Opel, Seat, Sthil, ALD Automitive, FinetworK, IE, etc.</p>
	<p>Hago todo tipo de trabajos, desde el diseño de email, newsletter, catálogos, banners, vallas, materiales PLV (póster, rollup, mupi, etc) hasta campañas 360º como es el caso del lanzamiento del Nuevo Opel Astra o el Nuevo Opel Grandland, así como diferentes presentaciones en Power Point. </p>
  <p>A continuación mostraré un ejemplo de los trabajos realizados.</p>`,
  "logoOpel": logoOpel,
  "logoOpel_2x": logoOpel_2x,
  "logoOpelHint": "Logo Opel",

  "logoFinetwork": logoFinetwork,
  "logoFinetwork_2x": logoFinetwork_2x,
  "logoFinetworkHint": "Logo Finetwork",

  "logoALD": logoALD,
  "logoALD_2x": logoALD_2x,
  "logoALDHint": "Logo ALD Automotive",

  "logoSTIHL": logoSTIHL,
  "logoSTIHL_2x": logoSTIHL_2x,
  "logoSTIHLHint": "Logo STIH",


  "sectionOpel": "Opel",
  "subSectionOpelGuias": "Guías",
  "subSectionOpelMaterialPLV": "Material PLV",
  "subSectionOpelBanner": "Banners",
  "subSectionOpelEmail": "Email",
  "sectionFinetwork": "Finetwork",
  "subSectionFinetworkMaterialPLV": "Material PLV",

  "opelGuias1": opelGuias1,
  "opelGuias1_2x": opelGuias1_2x,
  "opelGuias2": opelGuias2,
  "opelGuias2_2x": opelGuias2_2x,
  "opelGuias3": opelGuias3,
  "opelGuias3_2x": opelGuias3_2x,

  "opelPlv1": opelPlv1,
  "opelPlv1_2x": opelPlv1_2x,
  "opelPlv2": opelPlv2,
  "opelPlv2_2x": opelPlv2_2x,
  "opelPlv3": opelPlv3,
  "opelPlv3_2x": opelPlv3_2x,
  "opelPlv4": opelPlv4,
  "opelPlv4_2x": opelPlv4_2x,

  "opelBanner1": opelBanner1,
  "opelBanner1_2x": opelBanner1_2x,
  "opelBanner2": opelBanner2,
  "opelBanner2_2x": opelBanner2_2x,
  "opelBannerHint": "Banner gif para publicidad de opel",

  "opelEmail1": opelEmail1,
  "opelEmail1_2x": opelEmail1_2x,
  "opelEmail2": opelEmail2,
  "opelEmail2_2x": opelEmail2_2x,
  "opelEmail3": opelEmail3,
  "opelEmail3_2x": opelEmail3_2x,
  "opelEmail4": opelEmail4,
  "opelEmail4_2x": opelEmail4_2x,
  "opelEmailHint": "Diseño de email para opel ",

  "fiPlv1": fiPlv1,
  "fiPlv1_2x": fiPlv1_2x,
  "fiPlv2": fiPlv2,
  "fiPlv2_2x": fiPlv2_2x,
  "fiPlv3": fiPlv3,
  "fiPlv3_2x": fiPlv3_2x,
  "fiPlv4": fiPlv4,
  "fiPlv4_2x": fiPlv4_2x,
  "fiPlv5": fiPlv5,
  "fiPlv5_2x": fiPlv5_2x,
  "fiPlv6": fiPlv6,
  "fiPlv6_2x": fiPlv6_2x,
  "fiPlvHint": "Flyer de oferta mensual de Finetwork",
}

const content = [grupoSIMContent]
export default content;

