import { grupoSIMContent } from '../../content/grupo-sim';

interface GenericProps {
  isMobile: boolean;
}
const OpelPLV = ({ isMobile }: GenericProps) => {
  return (
    <>
      <h3 className="my-8">{grupoSIMContent.subSectionOpelMaterialPLV} </h3>
      <div className="flex flex-row pt-2 gap-y-4 justify-between flex-wrap">
        <div className="flex md:flex-row justify-between">
          <div className="flex md:flex-row justify-evenly flex-wrap">
            <div className="w-1/2 p-2">
              <img
                className="shadow-lg rounded-lg"
                alt={grupoSIMContent.logoOpelHint}
                src={grupoSIMContent.opelPlv1}
                srcSet={`
                  ${grupoSIMContent.opelPlv1} 300w, 
                  ${grupoSIMContent.opelPlv1_2x} 900w`}
                sizes={`(min-width: 932px) 932px, 100vw`}
              />
            </div>
            <div className="w-1/2 p-2">
              <img
                className="shadow-lg rounded-lg"
                alt={grupoSIMContent.logoOpelHint}
                src={grupoSIMContent.opelPlv2}
                srcSet={`
                  ${grupoSIMContent.opelPlv2} 300w, 
                  ${grupoSIMContent.opelPlv2_2x} 900w`}
                sizes={`(min-width: 932px) 932px, 100vw`}
              />
            </div>
          </div>
        </div>
        <img
          className="shadow-lg rounded-lg"
          alt={grupoSIMContent.logoOpelHint}
          src={grupoSIMContent.opelPlv3}
          srcSet={`
                  ${grupoSIMContent.opelPlv3} 300w, 
                  ${grupoSIMContent.opelPlv3_2x} 900w`}
          sizes={`(min-width: 932px) 932px, 100vw`}
        />

        <img
          className="shadow-lg rounded-lg"
          alt={grupoSIMContent.logoOpelHint}
          src={grupoSIMContent.opelPlv4}
          srcSet={`
                  ${grupoSIMContent.opelPlv4} 300w, 
                  ${grupoSIMContent.opelPlv4_2x} 900w`}
          sizes={`(min-width: 932px) 932px, 100vw`}
        />
      </div>
    </>
  );
};

export default OpelPLV;