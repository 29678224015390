const MenuRoutes = [
	{ path: '/acerca-de-mi', text: 'Sobre mi' },
	{ path: '/proyectos', text: 'Proyectos' },
	{ path: '/branding', text: 'Branding' },
	{ path: '/editorial', text: 'Editorial' },
	{ path: '/fotografia', text: 'Edición/Fotografí­a' },
	{ path: '/grupo-sim', text: 'Grupo SIM' },
];

export default MenuRoutes;
