
import { fotomontajesContent } from '../../content/fotografia';

interface GenericProps {
  isMobile: boolean;
}

const Fotomontajes = ({ isMobile }: GenericProps) => {
  return (
    <>
      <div className="flex flex-col md:flex-row p-8 md:px-24 md:pt-4 md:pb-8 md:gap-16">
        <div className="flex flex-col md:flex-col md:gap-4 md:w-2/3">
          <div className="">
            <h2 className="my-8 text-4xl">{fotomontajesContent.Title} </h2>
            <section
              className="content text-justify"
              dangerouslySetInnerHTML={{
                __html: fotomontajesContent.Content,
              }}
            ></section>
          </div>

          {isMobile && (
            <img
              className="w-full mb-4 md:mb-0 md:w-1/3 object-contain self-end shadow-lg rounded-lg"
              alt={fotomontajesContent.AltImage}
              src={fotomontajesContent.Image}
              srcSet={`
                  ${fotomontajesContent.Image} 300w, 
                  ${fotomontajesContent.Image_2x} 900w`}
              sizes={`
                  (min-width: 932px) 932px, 100vw
                `}
            />
          )}

          <img
            className="w-full object-contain self-end shadow-lg rounded-lg"
            alt={fotomontajesContent.HuevosAlt}
            src={fotomontajesContent.Huevos}
            srcSet={`
                  ${fotomontajesContent.Huevos} 300w, 
                  ${fotomontajesContent.Huevos_2x} 900w`}
            sizes={`(min-width: 932px) 932px, 100vw`}
          />
        </div>
        {!isMobile && (
          <img
            className="w-full mb-4 md:mb-0 md:w-auto object-contain self-end md:max-w-3xl shadow-lg rounded-lg"
            alt={fotomontajesContent.AltImage}
            src={fotomontajesContent.Image}
            srcSet={`
                  ${fotomontajesContent.Image} 300w, 
                  ${fotomontajesContent.Image_2x} 900w`}
            sizes={`
                  (min-width: 932px) 932px, 100vw
                `}
          />
        )}
      </div>
      <div className="flex flex-col md:flex-row p-8 md:px-24 md:pt-4 md:pb-8 md:gap-16 justify-between">
        <img
          className="w-full object-contain self-end shadow-md rounded-md md:w-auto"
          style={{ maxWidth: '550px', maxHeight: '448px' }}
          alt={fotomontajesContent.CabezaAlt}
          src={fotomontajesContent.Cabeza}
          srcSet={`
                  ${fotomontajesContent.Cabeza} 300w, 
                  ${fotomontajesContent.Cabeza_2x} 900w`}
          sizes={`
                  (min-width: 932px) 932px, 100vw
                `}
        />
        <img
          className="w-full object-contain self-end shadow-md rounded-md "
          style={{ maxWidth: '600px', maxHeight: '550px' }}
          alt={fotomontajesContent.CaretaAlt}
          src={fotomontajesContent.Careta}
          srcSet={`
                  ${fotomontajesContent.Careta} 300w, 
                  ${fotomontajesContent.Careta_2x} 900w`}
          sizes={`(min-width: 932px) 932px, 100vw`}
        />

      </div>
    </>
  );
};

export default Fotomontajes;
